import { DragEvent, FC, useRef, useState } from 'react';
import { Box, IconButton, Icon as MuiIcon } from '@mui/material';
import clsx from 'clsx';
import { ReactComponent as IconDrag } from 'assets/icons/DragBlockIcon.svg';
import { ReactComponent as IconPencil } from 'assets/icons/PencilIcon.svg';
import Icon, { ICONS } from 'components/lib/Icon';
import './EditWrapper.scss';

// Manage this array at top level to avoid unneccesary DOM queries per event firing
let dropzones: Element[] | null = null;

const findClosestDropzone = (mouseY: number) => {
  if (dropzones === null) {
    dropzones = Array.from(document.querySelectorAll('.block-dropzone'));
  }
  // If no dropzones or negative Y value (e.g. final drag event), do nothing
  if (dropzones.length === 0 || mouseY < 0) {
    return;
  }

  if (dropzones.length === 1) {
    dropzones[0].classList.add('closest-dropzone');
    return;
  }

  const closest = dropzones.reduce<{ index: number; distance: number }>(
    (previousClosest, currentElement, index) => {
      const { top, bottom } = currentElement.getBoundingClientRect();
      const distanceFromTop = Math.abs(top - mouseY);
      const distanceFromBottom = Math.abs(bottom - mouseY);
      const leastDistance = Math.min(distanceFromTop, distanceFromBottom);
      if (leastDistance < previousClosest.distance) {
        return { index, distance: leastDistance };
      }
      return previousClosest;
    },
    { index: -1, distance: Number.POSITIVE_INFINITY }
  );

  dropzones.forEach((dropzone, index) => {
    const addOrRemove = index === closest.index ? 'add' : 'remove';
    dropzone.classList[addOrRemove]('closest-dropzone');
  });
};

const EditWrapper: FC<{
  onEdit(): void;
  onDelete?(): void;
  dragKey: string | null;
  isDraggable: boolean;
  setIsDragging(arg: string | null): void;
  isEditable: boolean;
  className: string;
}> = ({
  children,
  onEdit,
  onDelete,
  dragKey,
  isDraggable,
  setIsDragging,
  className,
  isEditable
}) => {
  const [draggingBlock, setDraggingBlock] = useState<boolean>(false);
  const dragHandleRef = useRef<HTMLButtonElement>(null);
  const dragYPosition = useRef<number>(0);

  let target: Node | null = null;

  const editOptionsClass = clsx(
    'edit-options-wrapper',
    { 'dragging-block': draggingBlock },
    { 'not-draggable': !isDraggable },
    className
  );

  const onDragEndHandler = () => {
    setIsDragging(null);
    setDraggingBlock(false);
    dropzones?.forEach((dropzone) =>
      dropzone.classList.remove('closest-dropzone')
    );
    dropzones = null;
  };

  const onDragStartHandler = (event: DragEvent<HTMLDivElement>) => {
    // Prevent dragging the block if the user drags any element other than the drag handle
    if (!dragHandleRef?.current?.contains(target)) {
      event.preventDefault();
    } else {
      setDraggingBlock(true);
      setIsDragging(dragKey);
    }
  };

  const throttle = (func: () => void, wait: number) => {
    let lastTime = 0;

    return function () {
      const now = Date.now();
      if (now - lastTime >= wait) {
        lastTime = now;
        func();
      }
    };
  };

  window.addEventListener('dragover', (event) => {
    throttle(() => {
      dragYPosition.current = event.clientY;
    }, 250)();
  });

  return (
    <Box
      className={editOptionsClass}
      draggable
      onDrag={(event) => {
        findClosestDropzone(dragYPosition.current);
      }}
      onDragEnd={onDragEndHandler}
      onDragStart={onDragStartHandler}
    >
      <Box className="edit-options-buttons-container">
        {isEditable && (
          <IconButton onClick={onEdit}>
            <MuiIcon component={IconPencil} />
          </IconButton>
        )}
        {onDelete && (
          <IconButton onClick={onDelete}>
            <Icon className="edit-options-icon" icon={ICONS.TRASH} />
          </IconButton>
        )}
        {isDraggable && (
          <IconButton
            className="drag-icon-button"
            ref={dragHandleRef}
            onMouseDown={(event) => {
              target = event.currentTarget;
            }}
          >
            <MuiIcon component={IconDrag} />
          </IconButton>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default EditWrapper;
